import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:frame-missing", this.showCustomError.bind(this));
  }

  async showCustomError(event) {
    event.preventDefault();
    this.element.innerHTML = `
      <p class='turbo-frame-error text-danger'>通信エラーが発生しました。更新してください。</p>
      <a href="#" data-action="click->turbo-frame-error-handling#reloadPage"><i class="fa-solid fa-rotate mr-2"></i>更新する</a>
    `;
  }

  reloadPage() {
    window.location.reload();
  }
}
