import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["status", "closeReason", "joinedOn", "restrictedOn", "closeReasonContainer", "joinedOnContainer"]

  connect() {
    this.checkStatus();
    this.checkCloseReason();
    this.setRestrictedOn();
  }

  // Close(false)のときだけクローズ理由を表示
  checkStatus() {
    if (this.statusTarget.value === 'true') {
      this.closeReasonContainerTarget.style.display = 'none';
      this.joinedOnContainerTarget.style.display = 'none';
    } else {
      this.closeReasonContainerTarget.style.display = 'block';
      this.checkCloseReason();
    }
  }

  // クローズ理由が16(自社決定)のときだけ入社(予定)日を表示
  checkCloseReason() {
    if (this.closeReasonTarget.value === '16') {
      this.joinedOnContainerTarget.style.display = 'block';
    } else {
      this.joinedOnContainerTarget.style.display = 'none';
    }
  }

  // 入社日から2年後をRestrictedOnにセット, RestrictedOnはDBには保存されていない
  setRestrictedOn() {
    const joinedOn = this.joinedOnTarget.value;
    if (joinedOn) {
      const restrictedOn = new Date(joinedOn);
      restrictedOn.setFullYear(restrictedOn.getFullYear() + 2);
      this.restrictedOnTarget.value = restrictedOn.toISOString().slice(0, 10);
    }
  }
}
